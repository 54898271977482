// Load only the controllers used on the store_front (to help with PageSpeed performance)
import { Application } from "@hotwired/stimulus";
import Flatpickr from 'stimulus-flatpickr';
import ChooseALaterDateController from "./choose_a_later_date_controller";
import CounterController from "./counter_controller";
// TODO: TF RE-IMPLEMENT - no debug_panel currently being rendered
// import DevToolsController from "./dev_tools_controller";
import DynamicSelectOptionsController from "./dynamic_select_options_controller";
import GovXOfferController from "./gov_x_offer_controller";
import GtmController from "./gtm_controller";
import ModalController from "./modal_controller";
import NotificationsController from "./notifications_controller";
import PaypalController from "./paypal_controller";
import PagyInitializerController from "./pagy_initializer_controller";
import QuickSearchController from "./quick_search_controller";
import SliderController from "./slider_controller";
import StripeController from "./stripe_controller";
import StripePaymentController from "./stripe_payment_controller";
import ToastController from "./toast_controller";

// Personalization controllers
import DesignInputsController from "./personalization2/design_inputs_controller";
import DesignPickerController from "./personalization2/design_picker_controller";
import PreviewController from "./personalization2/preview_controller";
import TemplateController from "./personalization2/template_controller";

// Import style for flatpickr
require("flatpickr/dist/flatpickr.css");

const application = Application.start();
window.Stimulus = application;

application.register('choose-a-later-date', ChooseALaterDateController);
application.register("counter", CounterController);
// application.register("dev-tools", DevToolsController)
application.register("dynamic-select-options", DynamicSelectOptionsController);
application.register("gov-x-offer", GovXOfferController);
application.register("gtm", GtmController);
application.register('flatpickr', Flatpickr);
application.register('modal', ModalController);
application.register('notifications', NotificationsController);
application.register('quick-search', QuickSearchController);
application.register('pagy-initializer', PagyInitializerController);
application.register('paypal', PaypalController);
application.register('personalization2--design-inputs', DesignInputsController);
application.register('personalization2--design-picker', DesignPickerController);
application.register('personalization2--preview', PreviewController);
application.register('personalization2--template', TemplateController);
application.register('slider', SliderController);
application.register('stripe', StripeController);
application.register('stripe-payment', StripePaymentController);
application.register('toast', ToastController);
